import React from 'react'
import Link from 'gatsby-link'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import {individual, emotional, luxury, renting_car_title, renting_car_text, reserve_now, image_left1_alt, reserve_alt} from '../strings'
import {prefix, link_reserve} from '../langPrefix'
import image from "../../images/Picture-Enjoy-holidays-with-luxury-Rent-a-Car.jpg";

const LeftContent = ({ siteTitle }) => (
   <div className="column column-left">
        <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="title">
            <h2>{individual}, <br /> {emotional} <br /> {luxury} </h2>
            {/*<FontAwesomeIcon icon="arrow-down"/>*/}
            <Link to="/" className="icon"></Link>
        </div>
        <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="image">
            <img src={image} alt="Picture – Enjoy holidays with luxury Rent a Car" title="Picture – Enjoy holidays with luxury Rent a Car"/>
        </div>
        <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="content">
            <h3 className="content-title">{renting_car_title}</h3>
            <p className="content-text">{renting_car_text}</p>
             <Link to={prefix + link_reserve} className="button gold" title="Fill in the form, ask for additional information and reserve your car">{reserve_now}</Link>
        </div>
   </div>
)


export default LeftContent
