import React from 'react'
import Intro from '../../components/IndexPage/Intro/intro'
import LeftContent from '../../components/IndexPage/leftContent_en'
import RightContent from '../../components/IndexPage/rightContent_en'
import Header from '../../components/Header/header_en'
import Footer from '../../components/Footer/footer'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import LayoutEn from '../../components/layoutEn'

const IndexPage = ({data}) => (
  <LayoutEn>
    <div id="home">
     <Header/>

   <Intro/>
   <section className="section main">
         <div className="container">
         <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="center-line-box">
            <div className="line"></div>
         </ScrollAnimation>
          <div className="columns">
              <LeftContent/>
              <RightContent/>
          </div>
      </div>
   </section>
   <Footer/>
   </div>
  </LayoutEn>
)

export default IndexPage
